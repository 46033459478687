.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-content {
  margin: 0 12px;
}

.ph {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  margin: 0px -12px;
  padding: 8px 4px;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 12px;
}

.ph h1 {
  margin: 0;
  margin-bottom: 4px;
}

.table-main {
  font-weight: bold;
  color: #001529;
  font-size: 15px;
}

.dash-card .ant-card-body {
  padding: 24px;
  padding-top: 0;
}

.user-list {
  margin-top: 6px;
  font-weight: bold;
}

h4.d-ph {
  font-size: 14px;
  margin: 8px 0;
  color: #001529 !important;
}
h3.d-ph {
  font-size: 16px;
  margin: 8px 0;
  color: #001529 !important;
}

.missed-deadline {
  background: #fcb5b5;
}
.today-task {
  background: #fce1b5;
}

.missed-deadline,
.today-task,
.other-task {
  padding: 16px !important;
  border-radius: 8px;
  margin-bottom: 16px;
}
.adm-nav-bar {
  padding: 0;
}
